import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/(landingPage)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/bootstrap/dist/css/bootstrap.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-device-frameset/dist/styles/marvel-devices.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/css-device-frames/dist/device-frames.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/global.css");
