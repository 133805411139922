// app/providers.tsx
"use client";

import { NextUIProvider } from "@nextui-org/react";
import { TRPCReactProvider } from "@/trpc/react";
import { EnvironmentProvider } from "@/providers/app/EnvironmentProvider";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <EnvironmentProvider>
      <NextUIProvider>
        <TRPCReactProvider>{children}</TRPCReactProvider>
      </NextUIProvider>
    </EnvironmentProvider>
  );
}
